



































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import InnerImageZoom from "vue-inner-image-zoom";
import moment from "moment";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class ReturnDetail extends mixins(Navigation, CustomDate) {
  loader = false;
  dialogPaymentDetail = false;
  paymentPreview = {};

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("bill/getBill", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.pv-e35") + this.$route.params.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/returns",
      icon: "mdi-clipboard-text",
      title: `${this.$tc("Views.sn-1")} ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get bill() {
    let bill = this.$store.getters["bill/getBill"];
    bill.progress = this.getInvestmentTimeline(bill);
    return bill;
  }

  private checkPreviewType(previewType: string): boolean {
    let type = previewType.toLowerCase();

    if (
      type == ".jpeg" ||
      type == ".png" ||
      type == ".jpg" ||
      type == ".svg" ||
      type == ".webm"
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getInvestmentTimeline(bill: any) {
    const investment_start_date = moment(bill.date);
    const investment_end_date = moment(bill.end_date);
    const investment_current_date = moment();

    const investment_amount = bill.amount;

    const total_investment_time = investment_end_date.diff(
      investment_start_date
    );
    const current_investment_time = investment_current_date.diff(
      investment_start_date
    );
    let investment_current_percentage = Math.trunc(
      (100 * current_investment_time) / total_investment_time
    );

    if (investment_current_percentage < 0) {
      investment_current_percentage = 0;
    }

    if (investment_current_percentage > 100) {
      investment_current_percentage = 100;
    }

    return {
      investment_amount: investment_amount,
      investment_start_date: investment_start_date,
      investment_end_date: investment_end_date,
      investment_current_date: investment_current_date,
      total_investment_time: total_investment_time,
      current_investment_time: current_investment_time,
      investment_current_percentage: investment_current_percentage,
    };
  }
}
